<div class="flex flex--cols-2" [formGroup]="locationLot">
    <!-- upper tier municipality -->
    <mat-form-field appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.location.upper.tier.municipality" | translate | async }}
        </mat-label>
        <mat-select formControlName="upperTierCode" (selectionChange)="onUpperTierCodeChange($event)">
            <mat-option *ngFor="let item of upperTierMunicipalityOptions; let i = index; trackBy: trackByFn" [value]="item.upperTierCode" [tabIndex]="0">
                {{ item.upperTierName }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- lower tier municipality -->
    <mat-form-field [class.disabled]="!canEditLowerTier" appearance="{{ !canEditLowerTier ? 'fill' : 'outline' }}" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.location.lower.tier.municipality" | translate | async }}
        </mat-label>
        <mat-select formControlName="lowerTierCode" (selectionChange)="onLowerTierCodeChange($event)">
            <mat-option *ngFor="let item of lowerTierMunicipalityOptions; let i = index; trackBy: trackByFn" [value]="item.lowerTierCode" [tabIndex]="0">
                {{ item.lowerTierName }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- geotownship -->
    <mat-form-field [class.disabled]="!canEditGeotownship" appearance="{{ !canEditGeotownship ? 'fill' : 'outline' }}" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.location.lot.dialog.label.geotownship" | translate | async }}
        </mat-label>
        <mat-select formControlName="geotownship" (selectionChange)="onGeotownshipChange($event)">
            <mat-option *ngFor="let item of geotownshipOptions | orderBy : 'geotownship'; let i = index; trackBy: trackByFn" [value]="item.geotownship" [tabIndex]="0">
                <span>{{ item.geotownship }}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="flex-child flex-child--placeholder" aria-hidden="true">&nbsp;</div>

    <div class="flex-child">
        <!-- concession -->
        <ng-container *ngIf="!isConcessionOverriden; else concessionNameInput">
            <mat-form-field [class.disabled]="!canSetConcession" appearance="{{ !canSetConcession ? 'fill' : 'outline' }}" class="flex-child" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.concession" | translate | async }}
                </mat-label>
                <mat-select formControlName="concessionName" (selectionChange)="onConcessionChange($event)">
                    <mat-option *ngIf="!canSetConcession" value="" disabled>
                        <span>{{ "calculator.ss.location.lot.dialog.placeholder.must.select.geotownship" | translate | async }}</span>
                    </mat-option>
                    <mat-option *ngFor="let o of concessionOptions | orderBy : 'concession'; let i = index; trackBy: trackByFn" [value]="o.concession">
                        <span>{{ o.concession }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-template #concessionNameInput>
            <mat-form-field appearance="outline" class="flex-child" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.concession" | translate | async }}
                </mat-label>
                <input trim="blur" matInput placeholder="" formControlName="concessionName" [value]="locationLot.controls.concessionName?.value" maxlength="50" (change)="syncData()" />
                <mat-error *ngIf="locationLot.controls.concessionName.hasError('maxlength')">
                    {{ "validation.message.maxLength" | translate : [50] | async }}
                </mat-error>
                <mat-error *ngIf="locationLot.controls.concessionName.hasError('empty')">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </ng-template>

        <!-- enter unlisted concession -->
        <mat-checkbox
            class="float--right"
            formControlName="overrideConcession"
            [class.disabled]="!canSetConcession"
            role="checkbox"
            (change)="onUnlistedConcessionChange($event)"
            [checked]="locationLot.controls.overrideConcession.value"
            attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.enter.unlisted.concession' | translate | async }}">
            {{ "calculator.ss.location.lot.dialog.label.enter.unlisted.concession" | translate | async }}
        </mat-checkbox>
    </div>

    <div class="flex-child">
        <!-- lot -->
        <ng-container *ngIf="!isLotOverriden; else lotNameInput">
            <mat-form-field [class.disabled]="!canSetLot" appearance="outline" class="flex-child mt-1" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.lot" | translate | async }}
                </mat-label>
                <mat-select formControlName="lotName" (selectionChange)="onLotChange($event)">
                    <mat-option *ngIf="!canSetLot" value="" disabled>
                        <span>{{ "calculator.ss.location.lot.dialog.placeholder.must.select.geotownship" | translate | async }}</span>
                    </mat-option>
                    <mat-option *ngFor="let o of lotOptions | orderBy : 'lot'; let i = index; trackBy: trackByFn" [value]="o.lot">
                        <span>{{ o.lot }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-template #lotNameInput>
            <mat-form-field appearance="outline" class="flex-child mt-1" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.lot" | translate | async }}
                </mat-label>
                <input trim="blur" matInput placeholder="" formControlName="lotName" [value]="locationLot.controls.lotName?.value" maxlength="50" (change)="syncData()" />
                <mat-error *ngIf="locationLot.controls.lotName.hasError('maxlength')">
                    {{ "validation.message.maxLength" | translate : [50] | async }}
                </mat-error>
                <mat-error *ngIf="locationLot.controls.lotName.hasError('empty')">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </ng-template>

        <!-- enter unlisted lot -->
        <mat-checkbox
            class="float--right"
            formControlName="overrideLot"
            [class.disabled]="!canSetLot"
            role="checkbox"
            (change)="onUnlistedLotChange($event)"
            [checked]="locationLot.controls.overrideLot.value"
            attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.enter.unlisted.lot' | translate | async }}">
            {{ "calculator.ss.location.lot.dialog.label.enter.unlisted.lot" | translate | async }}
        </mat-checkbox>
    </div>
</div>

<div class="flex flex--cols-2 flex--nowrap" [formGroup]="f">
    <!-- total lot size -->
    <mat-form-field *ngIf="showLotSize" appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.mds.calculation.location.information.total.lot.size" | translate | async }}
        </mat-label>
        <input
            type="number"
            inputmode="decimal"
            matInput
            [fieldArea]="form.totalLotSizeInMetric"
            numeric
            precision="2"
            placeholder=""
            [value]="form.totalLotSizeInMetric.value | valueConverterPipe : conversionConstants.UNIT_TYPE_AREA | async | decimalPipe : 2 | zeroToBlank : form.totalLotSizeInMetric.pristine"
            formControlName="totalLotSizeInMetric"
            (change)="syncParentData()" />
        <span matSuffix class="mat-suffix">
            {{ conversionConstants.UNIT_TYPE_AREA | unitPipe | async }}
        </span>
        <mat-error *ngIf="form.totalLotSizeInMetric.hasError('max')">
            {{ "validation.message.between.0.1000000" | translate | async }}
        </mat-error>
        <mat-error *ngIf="form.totalLotSizeInMetric.hasError('min')">
            {{ "validation.message.between.0.1000000" | translate | async }}
        </mat-error>
    </mat-form-field>
    <div class="flex-child flex-child--flex-shrink" *ngIf="flags && flags.flagTotalLotSize">
        <app-flag nextTo="form-field" [data$]="observable(flags.flagTotalLotSize)"></app-flag>
    </div>
</div>

<div class="flex flex--cols-2" [formGroup]="rollNumber">
    <!-- roll number -->
    <mat-form-field appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.location.roll.number.label" | translate | async }}
        </mat-label>
        <input
            matInput
            (focus)="toggleFocus(rollNumber, true)"
            (change)="toggleFocus(rollNumber, false)"
            (input)="sliceInput(rollNumber, $event)"
            placeholder=""
            formControlName="rollNumber"
            [value]="rollNumber.get('rollNumber').value"
            maxlength="19" />
        <!-- digits count for roll number -->
        <mat-hint *ngIf="rollNumber.controls.isFocused.value === true">
            ({{ rollNumber.controls.numOfDigits.value }}
            <ng-container *ngIf="rollNumber.controls.numOfDigits.value <= 1; else plural"> {{ "calculator.ss.contact.location.roll.numbers.digits.single" | translate | async }}) </ng-container>
            <ng-template #plural> {{ "calculator.ss.contact.location.roll.numbers.digits.plural" | translate | async }}) </ng-template>
        </mat-hint>
        <mat-error *ngIf="rollNumber.get('rollNumber').hasError('maxlength')">
            {{ "validation.message.maxLength" | translate : [19] | async }}
        </mat-error>
    </mat-form-field>
</div>
