import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { Utils } from '@core/utilities/utils';
import { Label } from '@shared/models/common/label.model';
import { LabelClient } from '@shared/services/label.service';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-manage-label-dialog',
  templateUrl: './manage-label-dialog.component.html',
  styleUrls: ['./manage-label-dialog.component.scss']
})
export class ManageLabelDialogComponent extends BaseComponent implements OnInit {
  formAdd: UntypedFormGroup;
  formEdit: UntypedFormGroup;
  labels = [];

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<ManageLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private labelClient: LabelClient
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.bindFormAdd();
    this.all().subscribe();
  }

  all() {
    return this.labelClient.all().pipe(
      takeWhile(() => this.alive),
      tap(labels => {
        this.labels = labels || [];
        this.aodaFix();
      })
    );
  }

  edit(id: string) {
    const label = this.labels.find(v => Utils.matchStr(id, v.id));
    label.editMode = !label.editMode;
    if (label.editMode) {
      this.bindFormEdit(label);
    }
  }

  add() {
    if (this.formAdd.valid) {
      this.labelClient
        .save(
          new Label({
            userAccountId: this.cache.user?.id,
            parentId: null,
            labelName: this.formAddCtls.label.value
          })
        )
        .pipe(
          takeWhile(() => this.alive),
          tap(res => {
            this.labels.push(new Label(res));
            this.formAddCtls.label.reset();
            this.labelClient.next();
          })
        )
        .subscribe();
    }
  }

  update(label: Label) {
    if (this.formEdit.valid) {
      label.labelName = this.formEditCtls.label.value;
      this.labelClient
        .update(label)
        .pipe(
          takeWhile(() => this.alive),
          tap(() => {
            label.editMode = false;
            this.formEdit.reset();
            this.labelClient.next();
          })
        )
        .subscribe();
    }
  }

  delete(id: string) {
    const deleteLabel = () => {
      const index = this.labels.findIndex(v => Utils.matchStr(id, v.id));
      if (index > -1) {
        this.labels.splice(index, 1);
      }
    };
    this.labelClient
      .delete(id)
      .pipe(
        takeWhile(() => this.alive),
        tap(() => deleteLabel()),
        tap(() => this.labelClient.next())
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  private aodaFix() {
    setTimeout(() => {
      AODADialog.applyAllFixes();
    }, 0);
  }

  private bindFormAdd() {
    this.formAdd = this.fb.group({
      label: new UntypedFormControl('', {
        validators: [Validators.minLength(3), Validators.maxLength(20), Validators.required, ValidationFunctions.empty]
      })
    });
  }

  private bindFormEdit(label: Label) {
    this.formEdit = new UntypedFormGroup({
      label: new UntypedFormControl(label.labelName, {
        validators: [Validators.minLength(3), Validators.maxLength(20), Validators.required, ValidationFunctions.empty]
      })
    });
  }

  get formAddCtls() {
    return this.formAdd?.controls;
  }

  get formEditCtls() {
    return this.formEdit?.controls;
  }

  get object() {
    return Object;
  }
}
