<!-- input materials -->
<div class="flex-child mb-1">
    <strong>
        {{ "calculator.ss.treatment.input.materials" | translate | async }}
    </strong>
    <br />
    <span>
        <ng-container *ngIf="helper.canSeeInputMaterials(materialType, system, systemCalculation) as inputMaterials; else na">
            <ng-container *ngFor="let inputMaterial of inputMaterials; let i = index; trackBy: trackById">
                <ng-container *ngIf="i !== 0">,</ng-container>
                {{ helper.materialDescription(inputMaterial, systemCalculation) }}
            </ng-container>
        </ng-container>
        <ng-template #na>
            <abbr title="{{ 'notAvailable' | translate | async }}">
                {{ "na" | translate | async }}
            </abbr>
        </ng-template>
    </span>
</div>

<!-- material type name -->
<div class="flex-child pb-1 pl-0">
    <strong>
        {{ "calculator.organic.amendment.report.section.header.material-type" | translate | async }}
    </strong>
    <br />
    <span>
        {{ helper.materialTypeName(calculation)[lang$ | async] }}
    </span>
</div>

<div class="flex-child pb-1 pl-0" aria-hidden="true">&nbsp;</div>

<!-- total amount & dry matter range -->
<div class="flex-child pb-1 pl-0">
    <strong>
        {{ "calculator.ss.direct.util.summary.total.amount" | translate | async }}
        ({{ helper.materialFormName(materialType).i18nkey | translate | async }}{{ getStorageYearsFormatted$(", ", "") | async }})
    </strong>
    <br />
    <span>
        {{ calculation?.inputMaterial?.amountTotal | valueConverterPipe : conversionConstants.UNIT_TYPE_VOLUME2 | async | decimalPipe : 0 }}
        {{ conversionConstants.UNIT_TYPE_VOLUME2 | unitPipe | async }}
        (<ng-container *ngIf="helper.canSeeDryMatterRange(calculation?.inputMaterial); else dryMatterLow">
            {{ calculation?.inputMaterial?.dryMatterLow | decimalPipe : 1 }}-
            {{ calculation?.inputMaterial?.dryMatterHigh | decimalPipe : 1 }}
        </ng-container>
        <ng-template #dryMatterLow>
            {{ calculation?.inputMaterial?.dryMatterLow | decimalPipe : 1 }}
        </ng-template>
        % {{ helper.dryMatterUnit }})
    </span>
</div>

<!-- land applied amount & percentage -->
<div class="flex-child pb-1 pl-0">
    <strong>
        {{ "calculator.ss.direct.util.summary.land.applied" | translate | async }}
        {{ getStorageYearsFormatted$() | async }}
    </strong>
    <br />
    <span>
        <ng-container *ngIf="calculation?.landAppliedAmount; else na">
            <ng-container *ngIf="calculation?.inputMaterial?.materialForm === materialFormEnum.Liquid; else solidUnit">
                {{ calculation?.landAppliedAmount | valueConverterPipe : conversionConstants.UNIT_TYPE_LIQUID_VOLUME | async | decimalPipe : 0 }}
                {{ conversionConstants.UNIT_TYPE_LIQUID_VOLUME | unitPipe | async }}
            </ng-container>
            <ng-template #solidUnit>
                {{ calculation?.landAppliedAmount | valueConverterPipe : conversionConstants.UNIT_TYPE_MASS_LARGE | async | decimalPipe : 0 }}
                {{ conversionConstants.UNIT_TYPE_MASS_LARGE | unitPipe | async }}
            </ng-template>
            ({{ calculation?.landAppliedPercentage | decimalPipe : 1 }} %)
        </ng-container>
        <ng-template #na>
            <abbr title="{{ 'notAvailable' | translate | async }}">
                {{ "na" | translate | async }}
            </abbr>
        </ng-template>
    </span>
</div>

<!-- nutrient value source name -->
<div class="flex-child pb-1 pl-0">
    <strong>
        {{ "calculator.ss.report.section.nutrientUtilization.nutrient.source" | translate | async }}
    </strong>
    <br />
    <span> {{ helper.nutrientValueSourceName(materialType)?.i18nkey | translate | async }}&nbsp; ({{ helper.nutrientValueBasis?.i18nkey | translate | async }}) </span>
</div>

<!-- nutrient codes & test values -->
<ng-container *ngFor="let item of helper.nutrientCodes(calculation) | orderBy : 'sequenceId'">
    <div class="flex-child pb-1 pl-0">
        <strong>
            {{ item.descriptionLong[lang$ | async] | subScript }}
        </strong>
        <br />
        <ng-container *ngIf="helper.canSeeDatabank(materialType); else nutrientValueUser"> {{ item.databankWetBasis | decimalPipe : [item.precision] }} {{ item.unit }} </ng-container>
        <ng-template #nutrientValueUser>
            <ng-container *ngIf="!item.isNotDetected; else notDetected"> {{ item.testValueWetBasis | decimalPipe : [item.precision] | lessThanPipe : item.isLessThan }} {{ item.unit }} </ng-container>
            <ng-template #notDetected>
                {{ "calculator.nasm.tv.not.detected" | translate | async }}
            </ng-template>
        </ng-template>
    </div>
</ng-container>

<!-- notes -->
<div class="flex-child flex-child--full-width mt-1 ml-0" *ngIf="materialType?.get('notes').value as value">
    <strong>
        {{ "calculator.ss.treatment.notes" | translate | async }}
    </strong>
    <br />
    <span class="word-break--break-word white-space--pre-wrap">{{ value }}</span>
</div>

<!-- material graph -->
<div *ngIf="showGraph" class="flex-child flex-child--full-width mt-1 ml-0">
    <strong>
        {{ "graph" | translate | async }}
    </strong>
    <br />
    <app-line-chart [autoResize]="autoResize" [data]="materialGraphData"></app-line-chart>
</div>
