import { Component, HostListener, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent } from '@shared/components/base.component';

@Component(
	{
		selector: 'app-new-calculator-dialog',
		templateUrl: './new-calculator-dialog.component.html',
		styleUrls: ['./new-calculator-dialog.component.scss'],
		encapsulation: ViewEncapsulation.None
	})

export class NewCalculatorDialogComponent extends BaseComponent implements OnInit {
	constructor(private injector: Injector, public dialogRef: MatDialogRef<NewCalculatorDialogComponent>) {
		super(injector);
		dialogRef.disableClose = true;
	}

	@HostListener('window: keyup.esc') onKeyUp() {
		this.close();
	}

	close() {
		this.dialogRef.close();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	closeAndContinue(): void {
		this.dialogRef.close();
	}
}