import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Injector, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AODA } from '@core/utilities/aoda';
import { LocationLotData } from '@mstor/model/location-lot-data.model';
import { LocationLotDataService } from '@mstor/service/location-lot-data.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Observable, of } from 'rxjs';
import { delay, switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-location-lot-dialog',
  templateUrl: './location-lot-dialog.component.html',
  styleUrls: ['./location-lot-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LocationLotDialogComponent extends BaseComponent implements OnInit {
  f: UntypedFormGroup;
  isEdit = false;
  selectedUpperTier: any;
  selectedLowerTier: any;
  concessionOptions = [];
  lotOptions = [];
  concessionLotMap = new Map();
  showAsm: boolean;

  locationLots$: Observable<LocationLotData[]>;
  geotownshipOptions$: Observable<any[]>;

  constructor(private injector: Injector,
    public dialogRef: MatDialogRef<LocationLotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private locationLotDataService: LocationLotDataService,
    private el: ElementRef,
    private r2: Renderer2) {
    super(injector);
    this.isEdit = this.data.isEdit;
    this.f = this.data.form;
    this.selectedUpperTier = this.data.selectedUpperTier;
    this.selectedLowerTier = this.data.selectedLowerTier;
    this.showAsm = this.data.showAsm;
    this.dialogRef.disableClose = true;
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.upperTierCode.patchValue(this.selectedLowerTier.upperTierCode);
    this.form.lowerTierCode.patchValue(this.selectedLowerTier.lowerTierCode);
    this.refreshFormControls().subscribe();
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  syncData() {
    this.onUpdate.emit();
  }

  bindObservables() {
    // make sure it's always string as data type of returned lower tier code varies (int / string)
    this.geotownshipOptions$ = of(this.cache.geoTownships.filter(v =>
    (v.upperTierCode === this.selectedLowerTier.upperTierCode &&
      v.lowerTierCode.toString() === this.selectedLowerTier.lowerTierCode.toString())));
  }

  onGeotownshipChange(event) {
    this.refreshFormControls().subscribe();
    this.syncData();
  }

  onConcessionChange(event) {
    this.configureLotOptions();
    this.syncData();
  }

  onLotChange(event) {
    const selectedLot = this.lotOptions.find(l => l.lot === event.value);
    this.form.lotId.patchValue(selectedLot.lotId);
    this.syncData();
  }

  onUnlistedConcessionChange(event) {
    this.form.overrideConcession.patchValue(event.checked);
    this.form.overrideLot.patchValue(event.checked);
    this.configureConcessionName();
    this.configureLotName();
    this.syncData();
  }

  onUnlistedLotChange(event) {
    this.form.overrideLot.patchValue(event.checked);
    this.configureLotName();
    this.syncData();
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(this.f);
  }

  private refreshFormControls() {
    return this.geotownshipOptions$.pipe(
      takeWhile(() => this.alive),
      tap(geotownshipOptions => this.configureDefaultGeotownship(geotownshipOptions[0].geotownship)),
      delay(0),
      switchMap(() => this.locationLotDataService.get(this.form.geotownship.value)),
      switchMap(locationLots => this.refreshConcessionLotMap(locationLots)),
      tap(() => this.changeDetectorRef.detectChanges())
    );
  }

  private refreshConcessionLotMap(locationLots) {
    this.concessionLotMap = new Map();
    this.concessionOptions = [];
    this.lotOptions = [];
    return locationLots.map(lot => {
      // update concession/lot mapping here
      const concessionKey = lot.concession;
      if (!this.concessionLotMap.has(concessionKey)) {
        this.concessionLotMap.set(concessionKey, [lot]);
        // add unique concession to option list if first time adding to map
        this.concessionOptions.push(lot);
      } else {
        this.concessionLotMap.get(concessionKey).push(lot);
      }
      if (this.form.concessionName.value && this.form.concessionName.value === concessionKey) {
        this.lotOptions.push(lot);
      }
      return lot;
    });
  }

  private configureConcessionName() {
    if (this.form.overrideConcession.value === true) {
      this.form.concessionName.setValidators([Validators.maxLength(50), ValidationFunctions.empty]);
      this.f.updateValueAndValidity();
    } else {
      this.form.concessionName.clearValidators();
      this.f.updateValueAndValidity();
    }
  }

  private configureLotName() {
    if (this.form.overrideLot.value === true) {
      this.form.lotName.setValidators([Validators.maxLength(50), ValidationFunctions.empty]);
      this.form.lotId.patchValue(0);
      this.f.updateValueAndValidity();
    } else {
      this.form.lotName.clearValidators();
      this.f.updateValueAndValidity();
    }
  }

  private configureDefaultGeotownship(defaultGeotownship: string) {
    if (!this.form?.geotownship.value) {
      this.form?.geotownship.patchValue(defaultGeotownship);
    }
  }

  private configureLotOptions() {
    this.lotOptions = this.concessionLotMap.get(this.form.concessionName.value);
  }

  get canSetConcession() {
    return this.form.geotownship && this.form.geotownship.value;
  }

  get canSetLot() {
    return this.form.geotownship && this.form.geotownship.value;
  }

  get canAddLocationLot() {
    return this.form.geotownship.value && this.form.concessionName.value && this.form.lotName.value;
  }

  get isConcessionOverriden() {
    return this.form.overrideConcession.value === true;
  }

  get isLotOverriden() {
    return this.form.overrideLot.value === true;
  }

  get form() {
    return this.f?.controls;
  }
}
