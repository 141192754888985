import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Constants } from '@shared/constants/constants';

@Component({
  selector: 'app-application-phosphorus-index-dialog',
  templateUrl: './application-phosphorus-index-dialog.component.html',
  styleUrls: ['./application-phosphorus-index-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationPhosphorusIndexDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ApplicationPhosphorusIndexDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.cancel();
  }

  get constants() {
    return Constants;
  }
}
