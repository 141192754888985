import {
    HttpErrorResponse, HttpEvent,
    HttpHandler, HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AppConstants } from '@core/conf/app-constants';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpError } from '../../shared/models/common/http-error.enum';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private msalService: MsalService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {

                    if (error instanceof ErrorEvent) {
                        console.log('client side error', error);
                    } else {
                        // only redirect to defined http errors
                        if (!!AppConstants.HTTP_ERRORS.find(v => v.id === error.status)) {
                            this.redirect(error);
                        }
                        if (error.status === HttpError.HTTP_UNAUTHORIZED) {
                            this.logout();
                            this.login();
                        }
                    }
                    return throwError('error occured in application');
                })
            );
    }

    redirect(error: any) {
        // http status code passed into error component for further processing
        this.router.navigate(['/error', error]);
    }

    logout() {
        this.msalService.logout();
    }

    login() {
        sessionStorage.clear();
        setTimeout(() => {
            this.router.navigate(['/secure-home']);
        }, 100);
    }

    redirectToHome() {
        this.router.navigate(['/']);
    }
}
