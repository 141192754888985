import { Observable } from 'rxjs';
import { Worksheet } from '../../models/worksheet/Worksheet';

export class DialogData {
    id: number;
    title: string;
    message: string;
    msgSuffix: string;
    customMessage$: Observable<any>;
    btnOk: string;
    displayBtnOk: boolean;
    btnCancel: string;
    worksheet: Worksheet;

    constructor() {
        this.displayBtnOk = true;
    }
}
