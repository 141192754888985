<ng-container *ngIf="f && isAuthenticated" [formGroup]="f">
  <mat-form-field appearance="outline">
    <textarea name="comment" trim="blur" matInput maxlength="100" (change)="syncData().subscribe()"
      formControlName="comment" placeholder="{{ 'flag.comment.input.placeholder' | translate | async }}"
      attr.aria-label="{{ 'flag.comment.input.placeholder' | translate | async }}"></textarea>

    <mat-error *ngIf="notes?.hasError('maxlength')">
      {{ 'validation.message.maxLength.100' | translate | async }}
    </mat-error>
  </mat-form-field>
</ng-container>