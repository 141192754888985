<button
    *ngIf="data$ | async as data"
    type="button"
    class="flagComponent button {{ data.flagLevelId | flagIconClassPipe }}"
    (click)="openFlagDialog()"
    attr.aria-label="{{ 'aria.label.flag' | translate | async }}: {{ flagLevelI18n$ | async | translate | async }}"
    aria-hidden="false"
    matTooltip="{{ 'flag.info' | translate | async }}: {{ data.description ? data.description[lang$ | async] : (data.descriptionI18nKey | translate | async) }} ({{
        flagLevelI18n$ | async | translate | async
    }}) ">
    <mat-icon
        [class.mat-icon--text]="nextTo === 'text'"
        [class.mat-icon--input]="nextTo === 'form-field'"
        [class.mat-icon--input-error]="nextTo === 'form-field-with-error'"
        [class.mat-icon--not-set]="nextTo === 'not-set'">
        {{ data.flagLevelId | flagIconPipe }}
    </mat-icon>
</button>
