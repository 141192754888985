import { Directive, Input, OnInit } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[conditionalFocus]'
})
export class ConditionalFocusDirective implements OnInit {

    // tslint:disable-next-line: no-input-rename
    @Input('condition') condition: boolean;

    constructor(private matInput: MatInput) { }

    ngOnInit() {
        if (this.condition) {
            setTimeout(() => {
                this.matInput.focus();
            }, 0);
        }
    }
}
