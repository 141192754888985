<mat-form-field appearance="{{readonly ? 'fill' : 'outline'}}" class="yearMonthPickerComponent" color="accent">
	<mat-label>
		{{ label }}
	</mat-label>

	<input matInput [matDatepicker]="dp" id="{{id}}Input" [formControl]="control" [placeholder]="placeholder"
		[min]="minDate" [max]="maxDate" [readonly]="readonly" class="{{readonly ? 'datepicker-readonly' : ''}}">

	<mat-datepicker-toggle #dpt matSuffix [for]="dp" matTooltip="{{'tooltip.opencalendar' | translate | async}}"
		[matTooltipDisabled]="isDisabled(dp)" matTooltipPosition="below"></mat-datepicker-toggle>

	<mat-datepicker #dp startView="year" [calendarHeaderComponent]="headerComponent"
		(yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, dp, dpt)">
	</mat-datepicker>

	<mat-hint align="end" *ngIf="hint">
		{{ hint }}
	</mat-hint>
</mat-form-field>