<div [formGroup]="filterForm" [class.filter-applied]="filterApplied" [class.filter-not-applied]="!filterApplied" class="flex-child">
    <mat-form-field color="accent" floatLabel="never" appearance="outline">
        <input class="label-filter-box" matInput readonly attr.aria-label="{{ 'label.filter.text' | translate | async }}" value="{{ labelFilter.value | translate | async }}" type="text" disabled />
        <button
            matSuffix
            mat-icon-button
            aria-label="drop down"
            [matMenuTriggerFor]="menu"
            type="button"
            class="button button--green-tertiary"
            #filterMenuTrigger="matMenuTrigger"
            matTooltip="{{ 'filter.menu.button.tooltip.text' | translate | async }}"
            matTooltipPosition="after">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
    </mat-form-field>
    <mat-menu #menu="matMenu" class="menu-form-wrapper" [hasBackdrop]="false" yPosition="below" xPosition="before">
        <app-filter-menu #filterMenu (onUpdate)="filterByLabels($event, false)" (onClear)="clearLabels()" [trigger]="filterMenuTrigger"></app-filter-menu>
    </mat-menu>
</div>
