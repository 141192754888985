import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { BaseComponent } from '../base.component';
import { DialogData } from './DialogData';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  data: DialogData;

  constructor(
    private injector: Injector,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    super(injector);
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    AODADialog.applyAllFixes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.onNoClick();
  }
}
