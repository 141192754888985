import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from './DialogData';

@Component({
  selector: 'app-confirmation-dialog2',
  templateUrl: './confirmation-dialog2.component.html',
  styleUrls: ['./confirmation-dialog2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialog2Component {
  data: DialogData;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog2Component>,
    @Inject(MAT_DIALOG_DATA) data: DialogData) {
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.onNoClick();
  }
}
