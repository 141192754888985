<div id="horizontalGradientBarComponent" class="flex-child flex-child--full-width">
    <div class="gradient-bar-outer-wrapper flex flex-child flex--cols-3">
        <div *ngIf="leftLabel" class="gradient-left-label flex-child flex-child--flex-shrink">{{ leftLabel | translate | async }}</div>

        <div class="gradient-bar-inner-wrapper flex-child flex-child--flex-grow">
            <div class="gradient-bar" [style]="gradientBackground">
                <div *ngIf="topIndicator" class="thumb-above">
                    <span class="gradient-dial flex-child" [style.left]="dialPosition(topIndicator.percentage)"></span>
                    <mat-slider
                        thumbLabel
                        [displayWith]="topIndicator.displayWith"
                        [value]="topIndicator.value"
                        [style.left]="sliderPosition(topIndicator.percentage)"
                        [class.label--float-left]="topIndicator.percentage > 50"
                        class="cdk-focused {{ labelClass(topIndicator.value) }}"
                        tabindex="-1"
                        style="pointer-events: none"></mat-slider>
                </div>
                <div *ngIf="bottomIndicator" class="thumb-below">
                    <span class="gradient-dial flex-child" [style.left]="dialPosition(bottomIndicator.percentage)"></span>
                    <mat-slider
                        thumbLabel
                        [displayWith]="bottomIndicator.displayWith"
                        [value]="bottomIndicator.value"
                        [style.left]="sliderPosition(bottomIndicator.percentage)"
                        [class.label--float-left]="bottomIndicator.percentage > 50"
                        class="cdk-focused {{ labelClass(bottomIndicator.value) }}"
                        tabindex="-1"
                        style="pointer-events: none"></mat-slider>
                </div>
            </div>
        </div>

        <div *ngIf="rightLabel" class="gradient-right-label flex-child flex-child--flex-shrink">{{ rightLabel | translate | async }}</div>
    </div>
</div>
