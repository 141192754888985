import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {

    constructor(private dialog: MatDialog) { }

    instance<T>(T, data?: any, config?: any): MatDialogRef<T, MatDialogConfig> {
        const cfg = new MatDialogConfig();
        cfg.width = '450px';
        cfg.panelClass = 'my-dialog';
        cfg.autoFocus = false;
        if (data) {
            cfg.data = data;
        }
        if (config) {
            Object.assign(cfg, config);
        }
        return this.dialog.open(T, cfg);
    }

    closeAll() {
        this.dialog.closeAll();
    }
}
