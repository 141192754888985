import { Component, HostListener, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ArrayConstants } from '@shared/constants/array-constants';
import { DeleteKey } from '@shared/models/common/delete-key.enum';
import { UndeletableKey } from '@shared/models/common/undeletable-key.enum';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteConfirmDialogComponent extends BaseComponent {
  type: DeleteKey | UndeletableKey;
  showDeleteBtn = true;
  arg0: any;
  arg1: any;
  arg$: Observable<string>;
  i18nKey: string;
  cropTypeId: number;
  cropSubTypeId: number;

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(injector);
    this.type = data.type;
    this.arg0 = data.arg0;
    this.arg1 = data.arg1;
    this.arg$ = data.arg$;
    this.i18nKey = data.i18nKey;
    this.cropTypeId = data.cropTypeId;
    this.cropSubTypeId = data.cropSubTypeId;
    this.dialogRef.disableClose = true;
    if (data.showDeleteBtn !== undefined) {
      this.showDeleteBtn = data.showDeleteBtn;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  delete() {
    this.dialogRef.close(true);
  }

  isSimpleType(type: DeleteKey | any) {
    return ArrayConstants.DELETE_MSG_SIMPLE_TYPES.indexOf(type) > -1;
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.cancel();
  }

  get key() {
    return DeleteKey;
  }

  get undeletableKey() {
    return UndeletableKey;
  }
}
