<article *ngIf="data" id="tooltipDialogComponent">
	<h1 mat-dialog-title class="h1 h1--component-title">
		<ng-container *ngIf="data.title; else byTitleKey">
			{{ data.title }}
		</ng-container>
		<ng-template #byTitleKey>
			{{ data.titleKey | translate | async }}
		</ng-template>
	</h1>

	<div mat-dialog-content class="flex flex--cols-2">
		<button type="button" class="no-click button {{ data.btnClass }} flex-child flex-child--flex-shrink"
			[tabindex]="0" attr.aria-label="{{ 'aria.label.info.button' | translate | async }}">
			<mat-icon>info</mat-icon>
		</button>
		<p class="flex-child flex-child--flex-grow">
			<ng-container *ngIf="data.message; else byMsgKey">
				<span class="font-weight--medium" innerHTML="{{ data.message }}">
					{{ data.message }}
				</span>
			</ng-container>
			<ng-template #byMsgKey>
				<span class="font-weight--medium" innerHTML="{{ data.msgKey | translate:data.msgParams | async }}">
					{{ data.msgKey | translate:data.msgParams | async }}
				</span>
			</ng-template>
		</p>
	</div>

	<div mat-dialog-actions class="flex flex--end-vertical">
		<div class="flex-child flex-child--flex-shrink">
			<button type="button" mat-stroked-button class="button button--black-primary" (click)="close()">
				{{ 'dialog.action.close' | translate | async }}
			</button>
		</div>
	</div>
</article>