import { Injectable, Injector, PipeTransform } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MsalService } from '@azure/msal-angular';
import { AppConstants } from '@core/conf/app-constants';
import { Utils } from '@core/utilities/utils';
import { GhgFertilizerApplication } from '@ghg/model/ghg-fertilizer-application.model';
import { GhgMaterialApplication } from '@ghg/model/ghg-material-application.model';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Constants } from '@shared/constants/constants';
import { FieldInputType } from '@shared/models/common/field-input-type.enum';
import { Crop } from '@shared/models/worksheet/crop.model';
import { CacheService } from '@shared/services/cache.service';
import { DialogService } from '@shared/services/dialog.service';
import { FormService } from '@shared/services/form.service';
import { SharedCropService } from '@shared/services/shared-crop.service';
import { SharedFertilizerApplicationService } from '@shared/services/shared-fertilizer-application.service';
import { SharedMaterialApplicationService } from '@shared/services/shared-material-application.service';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddCropRotationDialogComponent } from '../fmp-field-input/add-crop-rotation-dialog/add-crop-rotation-dialog.component';
import { FmpFertilizerApplication } from '../model/fmp-fertilizer-application.model';
import { FmpFieldInput } from '../model/fmp-field-input.model';
import { FmpMaterialApplication } from '../model/fmp-material-application.model';
import { FmpMaterialType } from '../model/fmp-material-type.model';
import { FmpReportType } from '../model/fmp-report-type.model';
// import { FmpCalculationService } from './fmp-calculation.service';
import { SiteCropService } from './site-crop.service';
import { SiteFertilizerApplicationService } from './site-fertilizer-application.service';
import { SiteMaterialApplicationService } from './site-material-application.service';
import { CalculationService } from '@core/services/calculation.service';

// providedin 'any' necessary as service is used in multiple modules and needs to be destroyed with the module
@Injectable({ providedIn: 'any' })
export class FmpFieldInputService {
  fieldInputs$ = new BehaviorSubject(undefined);
  fieldInputsValueChange$ = new Subject();
  private fieldInputMenuTrigger: MatMenuTrigger;
  private selectedFieldinputId: string;
  private sharedCropService: SharedCropService | SiteCropService | any;
  private sharedMaterialApplicationService: SharedMaterialApplicationService | SiteMaterialApplicationService | any;
  private sharedFertilizerApplicationService: SharedFertilizerApplicationService | SiteFertilizerApplicationService | any;

  constructor(
    private injector: Injector,
    private msalService: MsalService,
    private dialogService: DialogService,
    private cache: CacheService,
    private calculationService: CalculationService,
    private formService: FormService
  ) {
    this.injectServices();
  }

  setMenuTrigger(menuTrigger: MatMenuTrigger) {
    this.fieldInputMenuTrigger = menuTrigger;
    return this;
  }

  fieldInputs(fieldSection?: UntypedFormGroup, inputType?: FieldInputType): FmpFieldInput[] {
    const list = [];
    const fieldInputTypes = inputType ? [inputType] : [FieldInputType.Crop, FieldInputType.Fertilizer, FieldInputType.Material];
    fieldInputTypes.forEach(type => {
      const formArray = this.formArray(type, fieldSection);
      if (formArray && formArray.length) {
        formArray.getRawValue().forEach((app, index) => {
          list.push(this.fieldInput(type, app, index));
        });
      }
    });
    list.sort((a, b) => +new Date(a.applicationDate) - +new Date(b.applicationDate));
    return list as FmpFieldInput[];
  }

  next(id?) {
    if (id) {
      this.fieldInputs$.next({ fieldInputs: this.fieldInputs(), id });
    } else {
      //the structure diff between this and the above subj is used in the input component in an if switch ...
      this.fieldInputs$.next(this.fieldInputs());
    }
  }

  allFarmApplications(): Map<string, Map<string, FmpFieldInput[]>> {
    const farmAppMap = new Map<string, Map<string, FmpFieldInput[]>>();
    this.farms.controls.forEach((farm: UntypedFormGroup) => {
      const fieldAppMap = new Map<string, FmpFieldInput[]>();
      const fields = farm.get('fields') as UntypedFormArray;
      fields.controls.forEach((field: UntypedFormGroup) => {
        const fieldSection = field.get('fieldSections.0') as UntypedFormGroup;
        const list = this.fieldInputs(fieldSection);
        fieldAppMap.set(field.get('id').value, list);
      });
      farmAppMap.set(farm.get('id').value, fieldAppMap);
    });
    return farmAppMap;
  }

  allFarmAppsByCroppingYear(): Map<number, FmpFieldInput[]> {
    const farmAppMap = new Map<number, FmpFieldInput[]>();
    [FieldInputType.Crop, FieldInputType.Fertilizer, FieldInputType.Material].forEach(type => {
      this.farms.controls.forEach((farm: UntypedFormGroup) => {
        const fields = farm.get('fields') as UntypedFormArray;
        fields.controls.forEach((field: UntypedFormGroup) => {
          const fieldSection = field.get('fieldSections.0') as UntypedFormGroup;
          const formArray = this.formArray(type, fieldSection);
          formArray?.getRawValue()?.forEach((app, index) => {
            const appCroppingYear = app.croppingYear ? app.croppingYear : this.getCroppingYearFromDate(app);
            if (farmAppMap.has(appCroppingYear)) {
              farmAppMap.get(appCroppingYear).push(this.fieldInput(type, app, index, false));
            } else {
              farmAppMap.set(appCroppingYear, [this.fieldInput(type, app, index, false)]);
            }
          });
        });
      });
    });

    return farmAppMap;
  }

  getCroppingYearFromDate(input) {
    const croppingYears = this.calculationService.calculation$.getValue()?.fieldCalculations.filter(f => {
      return f.fieldInputs.some(fi => {
        return fi.fieldInputID === input.id;
      });
    })?.[0]?.croppingYears;
    if (!croppingYears) {
      return Utils.getCroppingYearFromDate(input.applicationDate);
    }

    const utcAppDate = Utils.utcDateOf(input.applicationDate);
    const croppingYear = croppingYears
      .map(cy => {
        return { year: cy.croppingYear, startDate: Utils.utcDateOf(cy.startDate), endDate: Utils.utcDateOf(cy.endDate) };
      })
      .filter(cy => utcAppDate >= cy.startDate && cy.endDate >= utcAppDate)?.[0]?.year;

    const year = croppingYear ? croppingYear : Utils.getCroppingYearFromDate(input.applicationDate);
    return year;
  }

  saveCropApplication() {
    const crop = new Crop();
    const app = crop.toForm();
    // Set the default planting date from the crop sub-type data source
    const cropSubType = this.cache.allCropSubTypes.find(v => crop.cropSubTypeId === v.cropId);
    const corn = this.cache.equationValues.find(v => v.id === Constants.CORN);
    const nitrogen = this.cache.equationValues.find(v => v.id === Constants.NITROGEN);
    app.get('plantingDate').patchValue(Utils.adjustPlantingDateByCroppingYear(cropSubType.plantDate, crop.croppingYear));
    app.get('harvestDate').patchValue(Utils.adjustHarvestDateByCroppingYear(cropSubType.harvestDate, crop.croppingYear));
    app.get('fieldSectionId').patchValue(this.currentFieldSection.get('id').value);
    app.get('cropPrice').patchValue(corn.valueNumeric);
    app.get('costOfNitrogen').patchValue(nitrogen.valueNumeric);
    return this.saveFieldInput(FieldInputType.Crop, app);
  }

  saveFertilizerApplication() {
    const app = this.formService.isGhgWorksheet ? new GhgFertilizerApplication().toForm() : new FmpFertilizerApplication().toForm();
    app.get('fieldSectionId').patchValue(this.currentFieldSection.get('id').value);
    return this.saveFieldInput(FieldInputType.Fertilizer, app);
  }

  saveMaterialApplication() {
    const app = this.formService.isGhgWorksheet ? new GhgMaterialApplication().toForm() : new FmpMaterialApplication().toForm();
    if (this.firstMaterialType) {
      app.get('materialTypeId').patchValue(this.firstMaterialType.get('id').value);
    }
    app.get('fieldSectionId').patchValue(this.currentFieldSection.get('id').value);
    return this.saveFieldInput(FieldInputType.Material, app);
  }

  copyFieldInput(event) {
    const { service, formArray } = this.serviceAndFormArray(event);
    return service.get(event.id).pipe(
      switchMap(res => {
        const app = JSON.parse(JSON.stringify(res));
        Utils.swapIds(app);
        return service.save(app);
      }),
      tap((app: any) => {
        this.selectedFieldinputId = app.id;
        this.insertFieldInput(event, app, formArray);
        this.calculationService.calculate(this.f, FmpReportType.currentFieldOnly);
      }),
      tap(() => this.next(this.selectedFieldinputId))
    );
  }

  deleteFieldInput(event) {
    const id = event.id;
    const { service, formArray } = this.serviceAndFormArray(event);
    return service.delete(id).pipe(
      tap(success => {
        if (success) {
          const index = formArray.controls.findIndex(v => v.get('id').value === id);
          formArray.removeAt(index);
          this.calculationService.calculate(this.f, FmpReportType.currentFieldOnly);
        }
      }),
      tap(() => this.next())
    );
  }

  allCroppingYears(): number[] {
    if (!this.farms) {
      return [];
    }

    const years: number[] = [];

    this.farms.controls.forEach(farm => {
      ((farm as FormGroup).get('fields') as FormArray).controls.forEach(field => {
        ((field as FormGroup).get('fieldSections') as FormArray).controls.forEach(sec => {
          let secYears = this.croppingYears(sec as FormGroup);
          years.push(...secYears);
        });
      });
    });

    years.sort();
    return [...new Set(years)];
  }

  croppingYears(fieldSection: UntypedFormGroup): number[] {
    const list: number[] = [];
    const fieldInputTypes = [FieldInputType.Crop, FieldInputType.Fertilizer, FieldInputType.Material];
    fieldInputTypes.forEach(type => {
      const formArray = this.formArray(type, fieldSection);
      if (formArray && formArray.length) {
        formArray.getRawValue().forEach((app, index) => {
          const year = app.croppingYear ? app.croppingYear : this.getCroppingYearFromDate(app);
          list.push(year);
        });
      }
    });
    list.sort((a, b) => +a - +b);
    return [...new Set(list)];
  }

  private getAllFieldIdsForCroppingYear(fieldSection: UntypedFormGroup, year: number) {
    const fieldInputs = this.fieldInputs(fieldSection);

    return fieldInputs.filter(f => {
      const appCroppingYear = f.app.croppingYear ? f.app.croppingYear : this.getCroppingYearFromDate(f.app);
      return appCroppingYear < year;
    });
  }

  deleteAllFarmsPriorCroppingYear(year: number) {
    if (!year) {
      return EMPTY;
    }

    let deletes$ = [];

    this.farms.controls.forEach(farm => {
      ((farm as FormGroup).get('fields') as FormArray).controls.forEach(field => {
        ((field as FormGroup).get('fieldSections') as FormArray).controls.forEach(sec => {
          const fields = this.getAllFieldIdsForCroppingYear(sec as FormGroup, year);

          fields.forEach(f => {
            const { service, formArray } = this.serviceAndFormArrayFromFieldSection(f, sec);

            deletes$.push(service.delete(f.id).pipe(map(success => ({ id: f.id, success: success, formArray: formArray }))));
          });
        });
      });
    });

    let subj = new Subject();
    forkJoin(deletes$).subscribe(rr => {
      rr.forEach(r => {
        if (r.success) {
          const index = r.formArray.controls.findIndex(v => v.get('id').value === r.id);
          r.formArray.removeAt(index);
        }
      });

      this.calculationService.calculate(this.f, FmpReportType.allFarmsAndFields);
      this.next();
      subj.next(true);
      subj.complete();
    });
    return subj;
  }

  deletePriorCroppingYear(fieldSection: UntypedFormGroup, year: number) {
    if (!year) {
      return EMPTY;
    }

    let deletes$ = [];

    const fields = this.getAllFieldIdsForCroppingYear(fieldSection, year);

    fields.forEach(f => {
      const { service, formArray } = this.serviceAndFormArrayFromFieldSection(f, fieldSection);

      deletes$.push(service.delete(f.id).pipe(map(success => ({ id: f.id, success: success, formArray: formArray }))));
    });

    let subj = new Subject();
    forkJoin(deletes$).subscribe(rr => {
      rr.forEach(r => {
        if (r.success) {
          const index = r.formArray.controls.findIndex(v => v.get('id').value === r.id);
          r.formArray.removeAt(index);
        }
      });

      this.calculationService.calculate(this.f, FmpReportType.allFarmsAndFields);
      this.next();
      subj.next(true);
      subj.complete();
    });

    return subj;
  }

  onAddCropRotation() {
    return this.dialogService
      .instance(AddCropRotationDialogComponent, {}, { width: '90%' })
      .afterClosed()
      .pipe(
        map((cropRotation: any) => (cropRotation ? this.cropRotationArray(cropRotation) : EMPTY)),
        switchMap((apps: UntypedFormGroup[]) => (apps?.length ? forkJoin(this.cropRotationAPICalls(apps)) : EMPTY)),
        tap((crops: Crop[]) => {
          crops.forEach(c => this.crops.push(new Crop(c).toForm()));
          this.next(crops[crops.length - 1].id);
          this.openCropRotationConfirmation();
          this.fieldInputMenuTrigger?.focus();
        })
      );
  }

  cropTypeHeading(element: any, cropNamePipe: PipeTransform) {
    const cropTypeName$ = cropNamePipe.transform(element.app.cropTypeId, 'TYPE') as Observable<string>;
    const cropSubTypeName$ = cropNamePipe.transform(element.app.cropSubTypeId, 'SUB_TYPE') as Observable<string>;
    return combineLatest([cropTypeName$, cropSubTypeName$]).pipe(
      map(([cropTypeName, cropSubTypeName]) => {
        // #1543 special case for 'other' crop type
        const isOtherCropType = element.app.cropTypeId === AppConstants.CROP_TYPE_OTHER;
        const isSame = Utils.matchStr(cropTypeName, element.app.otherComment?.trim());
        if (isOtherCropType) {
          if (element.app.otherComment && !isSame) {
            return `${element.app.otherComment} ${cropTypeName}`;
          }
          return cropTypeName;
        } else {
          // regular case
          if (!Utils.matchStr(cropTypeName, cropSubTypeName)) {
            return `${cropTypeName}, ${cropSubTypeName}`;
          }
          return cropTypeName.trim();
        }
      })
    );
  }

  private openCropRotationConfirmation() {
    this.dialogService.instance(
      ConfirmationDialogComponent,
      {
        title: 'dialog.title.confirmation',
        message: 'dialog.message.crops.added',
        btnCancel: 'dialog.action.ok',
        btnOk: 'dialog.action.ok',
        btnOkStyle: 'button--green-primary',
        displayBtnOk: false
      },
      { disableClose: true }
    );
  }

  private cropRotationAPICalls(apps: UntypedFormGroup[]): any[] {
    return apps.map(v => this.sharedCropService.save(v.value));
  }

  private cropRotationArray(cropRotation: any) {
    const apps = [];
    for (let i = cropRotation.numberOfYears; i > 0; i--) {
      const crop = new Crop();
      crop.cropTypeId = cropRotation[`cropSubType${i}`].cropTypeId;
      crop.cropSubTypeId = cropRotation[`cropSubType${i}`].cropId;

      // Set the default planting date from the crop sub-type data source
      const cropSubType = this.cache.allCropSubTypes.find(v => crop.cropSubTypeId === v.cropId);
      crop.croppingYear =
        cropSubType && cropSubType.coverCropFlag === true ? cropRotation.croppingYear + i - 2 : cropRotation.croppingYear + i - 1;

      const app = crop.toForm();
      app.get('plantingDate').patchValue(Utils.adjustPlantingDateByCroppingYear(cropSubType.plantDate, crop.croppingYear));
      app.get('harvestDate').patchValue(Utils.adjustHarvestDateByCroppingYear(cropSubType.harvestDate, crop.croppingYear));
      app.get('fieldSectionId').patchValue(this.currentFieldSection.get('id').value);
      apps.push(app);
    }
    return apps;
  }

  private insertFieldInput(event: any, app: any, formArray: UntypedFormArray) {
    const formGroup = this.fieldInputFormGroup(event.type, app);
    formArray.insert(event.index + 1, formGroup);
  }

  private saveFieldInput(type: FieldInputType, app: UntypedFormGroup) {
    const { service, formArray } = this.serviceAndFormArray({ type });
    return service.save(app.getRawValue()).pipe(
      tap(appData => {
        const fieldInput = this.fieldInputFormGroup(type, appData);
        this.selectedFieldinputId = fieldInput.value.id;
        formArray.push(fieldInput);
      }),
      tap(() => {
        this.next(this.selectedFieldinputId);
      })
    );
  }

  private fieldInput(type: FieldInputType, app: any, index, dateAsString: boolean = true) {
    return new FmpFieldInput(
      index,
      app.id,
      app.name,
      type,
      type === FieldInputType.Crop ? app.plantingDate : app.applicationDate,
      app.createdDateTime,
      undefined,
      undefined,
      undefined,
      app,
      type === FieldInputType.Material ? this.materialTypeModels : undefined
    );
  }

  private formArray(type: FieldInputType, fieldSection?: UntypedFormGroup) {
    switch (type) {
      case FieldInputType.Crop:
        return fieldSection ? (fieldSection.get('crops') as UntypedFormArray) : this.crops;
      case FieldInputType.Fertilizer:
        return fieldSection ? (fieldSection.get('fertilizerApplications') as UntypedFormArray) : this.fertilizerApplications;
      case FieldInputType.Material:
        return fieldSection ? (fieldSection.get('materialApplications') as UntypedFormArray) : this.materialApplications;
    }
  }

  private serviceAndFormArray(event: any) {
    switch (event.type) {
      case FieldInputType.Material:
        return { service: this.sharedMaterialApplicationService, formArray: this.materialApplications };
      case FieldInputType.Fertilizer:
        return { service: this.sharedFertilizerApplicationService, formArray: this.fertilizerApplications };
      case FieldInputType.Crop:
        return { service: this.sharedCropService, formArray: this.crops };
    }
  }

  private serviceAndFormArrayFromFieldSection(event: any, fieldSection) {
    switch (event.type) {
      case FieldInputType.Material:
        return { service: this.sharedMaterialApplicationService, formArray: this.getMaterialApplications(fieldSection) };
      case FieldInputType.Fertilizer:
        return { service: this.sharedFertilizerApplicationService, formArray: this.getFertilizerApplications(fieldSection) };
      case FieldInputType.Crop:
        return { service: this.sharedCropService, formArray: this.getCrops(fieldSection) };
    }
  }

  private fieldInputFormGroup(type: FieldInputType, app: any) {
    switch (type) {
      case FieldInputType.Material:
        return new FmpMaterialApplication(app).toForm();
      case FieldInputType.Fertilizer:
        return new FmpFertilizerApplication(app).toForm();
      case FieldInputType.Crop:
        return new Crop(app).toForm();
    }
  }

  private injectServices() {
    this.sharedCropService = this.isAuthenticated ? this.injector.get(SharedCropService) : this.injector.get(SiteCropService);
    this.sharedMaterialApplicationService = this.isAuthenticated
      ? this.injector.get(SharedMaterialApplicationService)
      : this.injector.get(SiteMaterialApplicationService);
    this.sharedFertilizerApplicationService = this.isAuthenticated
      ? this.injector.get(SharedFertilizerApplicationService)
      : this.injector.get(SiteFertilizerApplicationService);
  }

  isDeletableFieldInputsFromNavNode(node: any) {
    const years = this.croppingYears(this.getCurrentFieldSectionFromNavNode(node));
    return years && years.length > 1;
  }

  getDeleteCroppingYears(fieldSection) {
    const years = this.croppingYears(fieldSection);
    if (years && years.length > 1) {
      const deleteYears = years.slice(1);
      return deleteYears;
    }
    return null;
  }

  getCurrentFarmFromNavNode(node: any) {
    if (!this.f || !node.farmId || !node.fieldId) {
      return null;
    }
    const farm = (this.f.get('farms') as UntypedFormArray).controls.find(farm => Utils.matchStr(farm.get('id').value, node.farmId));
    return farm;
  }

  getCurrentFieldFromNavNode(node: any) {
    const farm = this.getCurrentFarmFromNavNode(node);
    if (!farm) {
      return null;
    }

    const field = (farm.get('fields') as UntypedFormArray).controls.find(field => Utils.matchStr(field.get('id').value, node.fieldId));
    return field;
  }

  getCurrentFieldSectionFromNavNode(node: any): UntypedFormGroup {
    const field = this.getCurrentFieldFromNavNode(node);
    if (!field) {
      return null;
    }

    const fieldSection = field.get('fieldSections.0') as UntypedFormGroup;
    return fieldSection;
  }

  getCrops(fieldSection): UntypedFormArray {
    return fieldSection ? (fieldSection.get('crops') as UntypedFormArray) : undefined;
  }

  getMaterialApplications(fieldSection): UntypedFormArray {
    return fieldSection ? (fieldSection.get('materialApplications') as UntypedFormArray) : undefined;
  }

  getFertilizerApplications(fieldSection): UntypedFormArray {
    return fieldSection ? (fieldSection.get('fertilizerApplications') as UntypedFormArray) : undefined;
  }

  get f() {
    return this.formService.f;
  }

  get firstMaterialType() {
    return this.materialTypes ? this.materialTypes.controls[0] : undefined;
  }

  get currentFieldId() {
    return this.f.get('currentFieldId');
  }

  get farms() {
    return this.f ? (this.f.get('farms') as UntypedFormArray) : undefined;
  }

  get fields() {
    return this.currentFarm ? (this.currentFarm.get('fields') as UntypedFormArray) : undefined;
  }

  get currentFarm() {
    return this.farms && this.currentField
      ? (this.farms.controls.find(v => Utils.matchStr(v.get('id').value, this.currentField.get('farmId').value)) as UntypedFormGroup)
      : undefined;
  }

  get currentField() {
    let currentField: UntypedFormGroup;
    if (!this.farms) {
      return undefined;
    }
    this.farms.controls.forEach(farm => {
      const fields = farm.get('fields') as UntypedFormArray;
      fields.controls.forEach((field: UntypedFormGroup) => {
        if (Utils.matchStr(field.get('id').value, this.currentFieldId.value)) {
          currentField = field;
        }
      });
    });
    return currentField;
  }

  get currentFieldSection() {
    return this.currentField ? (this.currentField.get('fieldSections.0') as UntypedFormGroup) : undefined;
  }

  get crops(): UntypedFormArray {
    return this.currentFieldSection ? (this.currentFieldSection.get('crops') as UntypedFormArray) : undefined;
  }

  get materialApplications(): UntypedFormArray {
    return this.currentFieldSection ? (this.currentFieldSection.get('materialApplications') as UntypedFormArray) : undefined;
  }

  get fertilizerApplications(): UntypedFormArray {
    return this.currentFieldSection ? (this.currentFieldSection.get('fertilizerApplications') as UntypedFormArray) : undefined;
  }

  get materialTypes(): UntypedFormArray {
    return this.f.get('materialTypes') as UntypedFormArray;
  }

  get materialTypeModels() {
    return this.materialTypes.controls.map((mt: UntypedFormGroup) => new FmpMaterialType().toModel(mt));
  }

  get isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
