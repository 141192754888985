<mat-form-field [formGroup]="formGroup" appearance="{{ appearance }}" color="{{ color }}">
    <mat-label attr.aria-label="{{ label | translate | async }}">
        {{ label | translate | async }}
    </mat-label>
    <input
        matInput
        type="number"
        inputmode="decimal"
        numeric
        [valueConverter]="control"
        [conversionType]="conversionType"
        precision="{{ precision }}"
        numericType="{{ numericType }}"
        placeholder="{{ placeholder | translate | async }}"
        [value]="control?.value | valueConverterPipe : conversionType | async | decimalPipe : precision | zeroToBlank : control?.pristine"
        formControlName="{{ controlName }}"
        (change)="callbackFn()" />
    <span matSuffix class="mat-suffix">
        {{ conversionType | unitPipe | async }}
    </span>
    <mat-error *ngIf="control?.hasError('min') || control?.hasError('max')">
        {{ conversionType | validRange | async }}
    </mat-error>
    <mat-hint *ngIf="hintTemplate" align="{{ hintAlignment }}">
        <ng-container *ngTemplateOutlet="hintTemplate"></ng-container>
    </mat-hint>
</mat-form-field>
