/* eslint-disable no-undef */
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Utils } from '@core/utilities/utils';
import { ImportWorksheetComponent } from '@home/secure-home/worksheet-import/worksheet-import.component';
import { WorksheetService } from '@home/service/worksheet.service';
import { ApplyLabelDialogComponent } from '@shared/components/apply-label-dialog/apply-label-dialog.component';
import { BaseComponent } from '@shared/components/base.component';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { CopyableWorksheetTypeIds, ExportableWorksheetTypeIds, WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { Worksheet } from '@shared/models/worksheet/Worksheet';
import { UserContactService } from '@shared/services/user-contact.service';
import { WorksheetClient } from '@shared/services/worksheet.client';
import { EMPTY } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { LabelFilterComponent } from './label-filter/label-filter.component';

@Component({
  selector: 'secure-home',
  templateUrl: './secure-home.component.html',
  styleUrls: ['./secure-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureHomeComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild('worksheetTable', { static: true }) worksheetTable: any;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('labelFilter') labelFilter: LabelFilterComponent;

  loaded = false;
  worksheets: Worksheet[] = [];
  dataSource = new MatTableDataSource();
  columns = ['name', 'worksheetTypeId', 'updatedDateTime', 'actions'];

  get azureUser() {
    const all = this.msalService.instance.getAllAccounts();
    const first = all[0];
    return first.username;
  }

  get worksheetTypes() {
    return this.cache.worksheetTypes;
  }

  constructor(
    private injector: Injector,
    private worksheetClient: WorksheetClient,
    private worksheetService: WorksheetService,
    private userContactService: UserContactService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.load(this.azureUser).subscribe();
    this.configFilterSorter();
  }

  import() {
    let dialogRef = this.dialogService.instance(
      ImportWorksheetComponent,
      { title: 'dialog.title.import.worksheet' },
      { disableClose: true }
    );

    (dialogRef.componentInstance as ImportWorksheetComponent).openWorksheet
      .pipe(takeWhile(() => this.alive))
      .subscribe(worksheet => this.navigateTo(worksheet));

    dialogRef
      .afterClosed()
      .pipe(
        takeWhile(() => this.alive),
        switchMap(refresh => (refresh ? this.load(this.azureUser) : EMPTY))
      )
      .subscribe();
  }

  search(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  create(): void {
    this.worksheetService.create().subscribe();
  }

  delete(worksheetId: string) {
    this.worksheetService.delete(worksheetId).subscribe(success => {
      if (success) {
        const pos = this.worksheets.map(e => e.id).indexOf(worksheetId);
        this.worksheets.splice(pos, 1);
        this.sort();
        this.dataSource.data = this.worksheets;
        this.cd();
        this.menuTrigger.closeMenu();
      }
    });
  }

  copy(src: Worksheet): void {
    this.worksheetService.copy(src).subscribe();
  }

  export(worksheet: Worksheet): void {
    this.worksheetService
      .export(worksheet)
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  convert(src: Worksheet, key: WorksheetKeys) {
    this.worksheetService.convert(src, key).subscribe();
  }

  rename(worksheet: Worksheet): void {
    this.worksheetService.rename(worksheet).subscribe();
  }

  navigateTo(worksheet: Worksheet) {
    this.worksheetService.navigateTo(worksheet);
  }

  updateLabel(worksheets: Worksheet[]) {
    this.dataSource.data = worksheets;
    this.loadingService.stopLoading();
  }

  canCreateNmsp(worksheetTypeId: string) {
    return WorksheetTypeIds.FIELD_MANAGEMENT_PLAN === worksheetTypeId || WorksheetTypeIds.MANURE_STORAGE_SIZING === worksheetTypeId;
  }

  canCreateGhg(worksheetTypeId: string) {
    return [
      WorksheetTypeIds.FIELD_MANAGEMENT_PLAN,
      WorksheetTypeIds.MANURE_STORAGE_SIZING,
      WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN
    ].includes(worksheetTypeId as WorksheetTypeIds);
  }

  canCopy(worksheetTypeId: WorksheetTypeIds): boolean {
    return CopyableWorksheetTypeIds.includes(worksheetTypeId);
  }

  canExport(worksheetTypeId: WorksheetTypeIds): boolean {
    return ExportableWorksheetTypeIds.includes(worksheetTypeId);
  }

  worksheetType(id: string): string {
    const type = this.worksheetTypes.find(v => v.id.toLowerCase() === (!!id ? id.toLowerCase() : undefined));
    return type ? type.description[this.languageService.languageType] : '';
  }

  openLabelDialog(row) {
    const dialogRef = this.dialogService.instance(ApplyLabelDialogComponent, { worksheetId: row.id }, { width: '380px' });
    dialogRef.afterClosed().subscribe(() => instance.onUpdate.unsubscribe());
    const instance = dialogRef.componentInstance as ApplyLabelDialogComponent;
    instance.onUpdate.pipe(switchMap(() => this.labelFilter.load())).subscribe();
  }

  private load(azureUser: string) {
    return this.worksheetService.saveLocal(azureUser).pipe(
      tap(worksheet => {
        if (worksheet) {
          this.cache.deleteWorksheets();
        }
      }),
      switchMap(() => this.userContactService.bind(this.cache.user.id)),
      switchMap(() => this.worksheetClient.all(azureUser)),
      tap((worksheets: Worksheet[]) => {
        this.worksheets = worksheets;
        this.loaded = true;
        this.sort();
        this.filter();
        this.cd();
      })
    );
  }

  private filter() {
    this.dataSource.data = this.worksheets;
    this.dataSource.filterPredicate = (data: Worksheet, filter: string) => {
      return (
        data.name.toLowerCase().indexOf(filter) !== -1 ||
        this.worksheetType(data.worksheetTypeId).toLowerCase().indexOf(filter) !== -1 ||
        (data.updatedDateTime && data.updatedDateTime.toString().indexOf(filter) !== -1)
      );
    };
  }

  private sort() {
    Utils.sort(this.worksheets, 'updatedDateTime', true);
  }

  private configFilterSorter() {
    this.dataSource.sort = this.matSort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'worksheetTypeId':
          return this.worksheetType(item[property]);
        default:
          return item[property];
      }
    };
    this.dataSource.filterPredicate = (data: Worksheet, filter: string) => {
      if (!filter) {
        return true;
      }
      if (data.name !== filter) {
        return true;
      }
    };
  }
}
