<section id="topNavComponent">
    <header class="print--show" aria-hidden="true">
        <img src="../assets/images/ontario@2x-print.png" alt="{{ 'home.topnav.logo.ontario.alttext' | translate | async }}" aria-hidden="true" />
        <h1 class="h1 h1--page-title" aria-hidden="true">AgriSuite</h1>
        <div class="clear" aria-hidden="true"></div>
    </header>
    <mat-sidenav-container [style.overflow]="sideNavOpened ? 'visible' : 'hidden'" [style.z-index]="sideNavOpened ? '1' : '0'">
        <mat-sidenav
            #drawer
            class="sidenav display--block"
            fixedInViewport="true"
            tabindex="0"
            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
            [mode]="'top'"
            [autoFocus]="true"
            [opened]="sideNavOpened"
            position="end">
            <mat-toolbar>
                <mat-toolbar-row class="flex flex--cols-2">
                    <h2 class="h2 flex-child flex-child--flex-grow">
                        {{ "home.topnav.sidenav.menu.title" | translate | async }}
                    </h2>
                    <div class="flex-child flex-child--flex-shrink">
                        <button
                            type="button"
                            mat-icon-button
                            class="button button--black-tertiary"
                            tabindex="0"
                            (click)="drawer.close()"
                            matTooltip="{{ 'home.topnav.sidenav.menu.tooltip.close' | translate | async }}"
                            matTooltipPosition="left">
                            <mat-icon
                                aria-hidden="false"
                                attr.aria-label="{{ 'home.topnav.sidenav.menu.tooltip.close' | translate | async }}"
                                mat-icon-button
                                tabindex="0"
                                (click)="drawer.toggle()"
                                (keyup.enter)="drawer.toggle()">
                                close
                            </mat-icon>
                        </button>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
            <mat-nav-list>
                <a
                    mat-list-item
                    [tabindex]="0"
                    (click)="home()"
                    role="button"
                    (keydown.enter)="home()"
                    *ngIf="(!isAuthenticated && router.url !== '/') || (isAuthenticated && router.url !== '/secure-home')">
                    {{ "home.topnav.sidenav.menuitem.home" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (click)="createNew()" (keydown.enter)="createNew()" *ngIf="isAuthenticated && allowCreateNew">
                    {{ "home.topnav.sidenav.menuitem.createNew" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (click)="login()" (keydown.enter)="login()" *ngIf="!isAuthenticated">
                    {{ "home.topnav.sidenav.menuitem.signin" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (click)="signup()" (keydown.enter)="signup()" *ngIf="!isAuthenticated">
                    {{ "home.topnav.sidenav.menuitem.signup" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (keydown.enter)="contactUs()" (click)="contactUs()">
                    {{ "home.topnav.sidenav.menuitem.contactus" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (keydown.enter)="feedback()" (click)="feedback()">
                    {{ "dialog.title.feedback" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" (click)="logout()" (keydown.enter)="logout()" *ngIf="isAuthenticated">
                    {{ "home.topnav.sidenav.menuitem.logout" | translate | async }}
                </a>
                <a mat-list-item [tabindex]="0" *ngIf="version">
                    {{ version }}
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <header id="header" class="print--hide">
                <mat-toolbar class="flex flex--cols-3">
                    <a href="https://www.ontario.ca/" #ontarioLogo class="flex-child" target="_blank" title="{{ 'home.topnav.logo.ontario.title' | translate | async }}" rel="noopener">
                        <img src="../assets/images/ontario.ca.svg" alt="{{ 'home.topnav.logo.ontario.alttext' | translate | async }}" />
                    </a>
                    <h1 class="h1 h1--page-title flex-child text-align--center">
                        <a [routerLink]="routerLinkHome" title="Home" class="main-title"> AgriSuite </a>
                    </h1>
                    <div class="flex-child text-align--right">
                        <button
                            type="button"
                            mat-icon-button
                            class="button button--black-primary"
                            (click)="switchLanguage()"
                            id="language-button"
                            matTooltip="{{ 'home.topnav.tooltip.language' | translate | async }}"
                            matTooltipPosition="left">
                            <span class="locale" [attr.aria-label]="oppositeLanguage === 'en' ? 'English' : 'Français'" [attr.lang]="oppositeLanguage">
                                {{ oppositeLanguage }}
                            </span>
                        </button>
                        <button
                            type="button"
                            mat-icon-button
                            class="button button--black-primary"
                            (click)="sideNavOpened = true; drawer.toggle()"
                            id="hamburger-menu"
                            matTooltip="{{ 'home.topnav.sidenav.hamburger.tooltip.text' | translate | async }}"
                            matTooltipPosition="above">
                            <span class="material-icons"> menu </span>
                        </button>
                    </div>
                </mat-toolbar>
            </header>
        </mat-sidenav-content>
    </mat-sidenav-container>
</section>
