import { Component, HostListener, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TooltipDialogData } from '@shared/components/tooltip-dialog/TooltipDialogData';

@Component({
  selector: 'app-tooltip-dialog',
  templateUrl: './tooltip-dialog.component.html',
  styleUrls: ['./tooltip-dialog.component.scss']
})
export class TooltipDialogComponent {

  data: TooltipDialogData;

  constructor(
    public dialogRef: MatDialogRef<TooltipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: TooltipDialogData) {
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }
}
