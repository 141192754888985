import { Inject, Injectable } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LanguageType } from '../models/common/language-type.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // tslint:disable-next-line: variable-name
  private _languageType: LanguageType = undefined;
  private subject = new BehaviorSubject(this._languageType);
  // tslint:disable-next-line: variable-name
  private _languageType$: Observable<string> = this.subject.asObservable();

  constructor(
    private matDatepickerIntl: MatDatepickerIntl,
    private _dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  translateDatepicker() {
    return this.languageType$.pipe(
      tap(lang => {
        this.matDatepickerIntl.calendarLabel = lang === 'en' ? 'Calendar' : 'Calendrier';
        this.matDatepickerIntl.openCalendarLabel = lang === 'en' ? 'Open the calendar' : 'Ouvrir calendrier';
        this.matDatepickerIntl.prevMonthLabel = lang === 'en' ? 'Previous month' : 'Mois précédent';
        this.matDatepickerIntl.nextMonthLabel = lang === 'en' ? 'Next month' : 'Mois prochain';
        this.matDatepickerIntl.prevYearLabel = lang === 'en' ? 'Previous year' : 'Année précédente ';
        this.matDatepickerIntl.nextYearLabel = lang === 'en' ? 'Next year' : 'Année prochaine';
        this.matDatepickerIntl.prevMultiYearLabel = lang === 'en' ? 'Previous multi-year' : 'Pluriannuel précédent';
        this.matDatepickerIntl.nextMultiYearLabel = lang === 'en' ? 'Next multi-year' : 'Pluriannuel prochain';
        this.matDatepickerIntl.switchToMonthViewLabel = lang === 'en' ? 'Switch to month view' : 'Changer à l’affichage mensuel ';
        this.matDatepickerIntl.switchToMultiYearViewLabel = lang === 'en' ? 'Switch to year view' : 'Changer à l’affichage annuel';
      })
    );
  }

  get languageType() {
    return this._languageType;
  }

  set languageType(languageType: LanguageType) {
    this._languageType = languageType;
    this.subject.next(this._languageType);

    this._locale = languageType;
    this._dateAdapter.setLocale(this._locale);
  }

  get languageType$() {
    return this._languageType$;
  }
}
